
<template>
  <div class="con manage-scroll">
    <div class="sta-content" v-loading="loading">
      <div class="sta-one" v-loading="loading1" v-if="sensorName.indexOf('C1') !== -1">
        <chart-title :timeArr="timeList" :sensor-detai-list="windList" :sensor-show="true" :sensor-mul="true" :range-show="true" :title-text="chartData.title" :sensor-type="sensorName" @changeForm="getData"></chart-title>
        <chart-one ref="chartThree" :value="chartData"></chart-one>
      </div>
      <div class="sta-one" v-loading="loading2" v-if="sensorName.indexOf('A1') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :sensor-show="true" :range-show="true" :title-text="chartData1.title" :sensor-type="sensorName" @changeForm="getData1"></chart-title>
        <chart-two :value="chartData1"></chart-two>
      </div>
      <div class="sta-one" v-loading="loading3" v-if="sensorName.indexOf('A2') !== -1">
        <chart-title :sensor-detai-list="sensorDetaiList" :sensor-show="true" :range-show="true" title-text="温度分布图" @changeForm="getData2"></chart-title>
        <chart-three :value="chartData2"></chart-three>
      </div>
    </div>
  </div>
</template>

<script>
  import chartOne from "../charts/chart01.vue";
  import chartTwo from "../../environment/charts/chart02.vue";
  import chartThree from "../../environment/charts/chart03.vue";
  import chartTitle from "../../common/components/chartTitle";
  export default {
    components: {
      chartTitle,chartOne,chartTwo,chartThree
    },
    data() {
      return {
        loading:false,
        loading1:false,
        loading2:false,
        loading3:false,
        chartData: {
          title:'所有测点风级分布图',
          xAxis:[],
          series:[]
        },
        chartData1: {
          title:'温度散点图',
          yAxis:{name:'TEM-27(℃)', min:0, max:0},
          xAxis:[],
          data1:{name:'实测值', data:[]},
          markLine:[],
          markLineList:[],
          colorList: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
        },
        chartData2: {
          xAxis: {name:'温度', data:[]},
          yAxis: {name:'频数'},
          data:[]
        },
        sensorDetaiList:[],
        windList:[],
        sensorName:'',
        timeList:[]
      };
    },
    created() {
      let date = new Date();
      this.timeList[0] = this.$moment(date).subtract(4, "days").format("YYYY-MM-DD");
      this.timeList[1] = this.$moment(date).format("YYYY-MM-DD");
    },
    methods:{
      changeSensorDetail(data,name){
        // this.sensorName = name
        if (data.length > 0){
          this.sensorDetaiList = data;
          this.windList = []
          this.sensorName = data[0].chart
          if (data){
            for (let item of data){
              if (item.type === 'S'){
                this.windList.push(item)
              }
            }
          }
        }
      },
      getData(data){
        if (data && data.sensorIdList && data.sensorIdList.length === 0){
          return
        }
        let params = {
          type:0,
          sensorIdList:data.sensorIdList
        }
        if (data.time.length > 0){
          if (data.time[0].length < 12){
            data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
          }
          params.startTime = data.time[0]
          params.endTime = data.time[1]
        }
        if (data.sensorIdList && data.time.length > 0){
          // this.loading1 = true
          this.loading = true;
          this.$http.post("/statistics/effect/wind/scale",params).then(res=>{
            if(res.success){
              this.chartData.series = []
              this.chartData.xAxis = res.data.xaxis
              if (res.data.dataList){
                for (let i = 0 ; i < res.data.dataList.length; i++){
                  this.chartData.series.push({name:res.data.dataList[i].type,data:res.data.dataList[i].yaxis})
                }
              }
              this.$refs["chartThree"].loadEcharts(this.chartData);
            } else {
              this.changeLoad1()
            }
            this.loading = false;
          })
        }
      },
      getData1(data){
        let params = {
          type:0,
          sensorId:data.sensorId
        }
        if (data.time.length > 0){
          if (data.time[0].length < 12){
            data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
          }
          params.startTime = data.time[0]
          params.endTime = data.time[1]
        }
        if (data.sensorId && data.time.length > 0){
          // this.loading2 = true
          this.loading = true;
          this.$http.post("/statistics/environment/humiture/scatter",params).then(res=>{
            if(res.success){
              this.chartData1.xAxis = res.data.xaxis
              this.chartData1.data1.data = res.data.scatterData.yaxis
              this.chartData1.markLine = JSON.parse(JSON.stringify(res.data.markLineList))
              this.chartData1.yAxis.min = res.data.ymin
              this.chartData1.yAxis.max = res.data.ymax
              this.chartData1.markLineList = JSON.parse(JSON.stringify(res.data.markLineList))
            } else {
              this.changeCommonLoad2()
            }
            this.loading = false;
          })
        }
      },
      getData2(data){
        let params = {
          type:0,
          sensorId:data.sensorId
        }
        if (data.time.length > 0){
          if (data.time[0].length < 12){
            data.time = [data.time[0] + ' 00:00:00',data.time[1] + ' 23:59:59']
          }
          params.startTime = data.time[0]
          params.endTime = data.time[1]
        }
        if (data.sensorId && data.time.length > 0){
          // this.loading3 = true
          this.loading = true;
          this.$http.post("/statistics/environment/humiture/histogram",params).then(res=>{
            if(res.success){
              this.chartData2.xAxis.data = res.data.xaxis
              this.chartData2.data = res.data.yaxis
            } else {
              this.changeCommonLoad3()
            }
            this.loading = false;
          })
        }
      },
      changeLoad1(){
        this.loading1 = false
      },
      changeCommonLoad2(){
        this.loading2 = false
      },
      changeCommonLoad3(){
        this.loading3 = false
      }
    }
  };
</script>

<style scoped>
  .manage-scroll{
    height: calc(100% - 400px);
  }
  .sta-title{
    height: 60px;
    padding: 0 20px;
    background: #fff;
    margin-bottom: 10px;
  }
  .title-text{
    font-size: 16px;
    color: #1A67D0;
    font-weight: 900;
  }
  .sta-content {
    height: 100%;
    background-color: #fff;
  }
  .sta-one{
    width: 100%;
    height: 400px;
    background: #fff;
    margin-bottom: 10px;
    padding: 20px;
    position: relative;
  }
  .sta-two{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .sta-con{
    width: 49.7%;
    height: 100%;
    padding: 20px;
    background: #fff;
    position: relative;
  }
  /deep/ .el-form-item{
    margin-bottom: 0;
  }
  /deep/ .el-date-editor .el-range-separator{
    padding: 0;
  }
  .threshold{
    position: absolute;
    right: 0;
    top: 94px;
    width: 144px;
  }
  .th-item{
    display: flex;
    align-items: center;
    height: 25px;
    line-height: 25px;
  }
  .th-item:hover{
    cursor: pointer;
  }
  .th-icon{
    width: 20px;
    height: 2px;
    margin-right: 10px;
  }
</style>
